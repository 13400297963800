document.addEventListener("DOMContentLoaded", function() {
  /**
   * V2 - Accordéons js pure -- JS dans global.js (d'autres accordéons en place)
   * - Si 1er élément ouvert, on ajoute la classe open
   */



	/**
   * V1 - Bootstrap (Plus utilisé ?)
	 * Test Accordéons Reacts : Générer les attributs au chargement de la page
	 * Opti ? : Possible de générer au moment de la création block react (??)
	 */

	// let accordions = document.getElementsByClassName('wp-block-ikadia-accordeons-react')

	// /* Pour chaque accordéon, on attribue un id : accordeon-0, accordeon-1... */
	// for (var i = 0; i < accordions.length; i++) {
	// 	let idParent = accordions[i].id + "-" + i
	// 	let accordionsItems = accordions[i].getElementsByClassName('accordion-item')

	// 	accordions[i].id = idParent

	// 	/* pour chaque item, on attribue un id : accordion-item-0-0, accordion-item-0-1...
	// 	 + maj des attributs pour gérer ouverture/fermeture correctement */
	// 	for (var j = 0; j < accordionsItems.length; j++) {
	// 		let itemHeader = accordionsItems[j].querySelector('.accordion-header')
	// 		let itemButton = accordionsItems[j].querySelector('.accordion-button')
	// 		let itemBody = accordionsItems[j].querySelector('.accordion-collapse')
	// 		let idHeader = itemHeader.id + "-" + i + "-" + j
	// 		let idBody = itemBody.id + "-" + i + "-" + j

	// 		/* Ouvre le 1er élément si opition cochée */
	// 		if (accordions[i].getAttribute("data-first") === "open") {
	// 			if (j === 0) {
	// 				itemButton.classList.remove('collapsed')
	// 				itemBody.classList.add('show')
	// 			}
	// 		}
	// 		/* Ajoute le data-bs-parent si ouverture multiple décochée */
	// 		if (accordions[i].getAttribute("data-multiple") === "false") {
	// 			//itemBody.dataset.bsParent =  "#" + idParent
	// 			let closestParent = itemBody.closest('.accordion').id
	// 			itemBody.dataset.bsParent =  "#" + closestParent
	// 		}

	// 		itemHeader.id = idHeader

	// 		itemButton.dataset.bsTarget = "#" + idBody
	// 		itemButton.setAttribute('aria-controls', idBody)

	// 		itemBody.id = idBody
	// 		itemBody.setAttribute('aria-labelledby', idHeader)
	// 	}
	// }
})
